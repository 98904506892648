import * as React from 'react';
import Cookies from 'js-cookie';
import { CookieConsentContext } from './cookie-consent-context';

function useCookieContext() {
  const context = React.useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider',
    );
  }
  return context;
}
export function useCookies() {
  const setCookie = (
    name: string,
    value: string,
    options?: Cookies.CookieAttributes,
  ) => {
    Cookies.set(name, value, options);
  };

  const getCookie = (name: string) => {
    return Cookies.get(name);
  };

  const removeCookie = (name: string, options?: Cookies.CookieAttributes) => {
    Cookies.remove(name, options);
  };

  return {
    setCookie,
    getCookie,
    removeCookie,
  };
}

const isProduction = process.env.NODE_ENV === 'production';

const cookieOptions = {
  expires: 365,
  sameSite: 'strict',
  secure: isProduction,
} as const;

export function useCookieConsent() {
  const { isInitialized, cookieConsent, setCookieConsent } = useCookieContext();
  const { setCookie } = useCookies();

  const acceptAll = () => {
    setCookie('consent', 'accept_all', cookieOptions);
    setCookieConsent(true);
  };

  const acceptRequired = () => {
    setCookie('consent', 'accept_required', cookieOptions);
    setCookieConsent(true);
  };

  const rejectAll = () => {
    setCookie('consent', 'reject_all', cookieOptions);
    setCookieConsent(false);
  };

  return { isInitialized, cookieConsent, acceptAll, acceptRequired, rejectAll };
}
