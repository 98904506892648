'use client';

import { useCookieConsent } from '@/components/client/cookie-consent/cookie-consent-hooks';
import { cn } from '@/lib/utils';

type CookieNoticeProps = {
  children: React.ReactNode;
  className?: string;
  debug?: boolean;
};

export default function CookieNotice({
  children,
  className,
  debug = false,
}: CookieNoticeProps) {
  const { isInitialized, cookieConsent } = useCookieConsent();

  const isCoookieNoticeHidden =
    !isInitialized || (cookieConsent !== null && !debug);

  if (isCoookieNoticeHidden) return null;

  return (
    <div
      className={cn(
        'relative inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function AcceptAllButton({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const { acceptAll } = useCookieConsent();

  return (
    <button
      className={cn(
        'rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900',
        className,
      )}
      onClick={acceptAll}
      type="button"
    >
      {children}
    </button>
  );
}

export function AcceptRequiredButton({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const { acceptRequired } = useCookieConsent();

  return (
    <button
      className={cn('text-sm font-semibold leading-6 text-gray-900', className)}
      onClick={acceptRequired}
      type="button"
    >
      {children}
    </button>
  );
}

export function RejectAllButton({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const { rejectAll } = useCookieConsent();

  return (
    <button
      className={cn('text-sm font-semibold leading-6 text-gray-900', className)}
      onClick={rejectAll}
      type="button"
    >
      {children}
    </button>
  );
}
