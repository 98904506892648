'use client';

import * as React from 'react';

export type CookieConsentContext = {
  cookieConsent: boolean | null;
  setCookieConsent: (value: boolean | null) => void;
  isInitialized: boolean;
};

export const CookieConsentContext =
  React.createContext<CookieConsentContext | null>(null);
