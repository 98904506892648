'use client';

import type { ReactNode } from 'react';
import * as React from 'react';
import { CookieConsentContext } from './cookie-consent-context';
import { useCookies } from './cookie-consent-hooks';

type CookieConsentProviderProps = {
  children: ReactNode;
};

export function CookieConsentProvider({
  children,
}: CookieConsentProviderProps) {
  const { getCookie } = useCookies();

  const [cookieConsent, setCookieConsent] = React.useState<boolean | null>(
    null,
  );
  const [isInitialized, setIsInitialized] = React.useState(false);

  const getInitialCookieConsent = React.useCallback(() => {
    const consentCookie = getCookie('consent');
    switch (consentCookie) {
      case 'accept_all':
      case 'accept_required':
        return true;
      case 'reject_all':
        return false;
      default:
        return null;
    }
  }, [getCookie]);

  React.useEffect(() => {
    setCookieConsent(getInitialCookieConsent());
    setIsInitialized(true);
  }, [getInitialCookieConsent]);

  const value: CookieConsentContext = {
    cookieConsent,
    setCookieConsent,
    isInitialized,
  };

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
}
