'use client';

export function getTrpcUrl() {
  if (!process.env.NEXT_PUBLIC_API_URL) {
    throw new Error('NEXT_PUBLIC_API_URL is not defined');
  }

  return `${getApiUrl()}/trpc`;
}

export function getStage() {
  if (!process.env.NEXT_PUBLIC_STAGE) {
    throw new Error('NEXT_PUBLIC_STAGE is not defined');
  }

  return process.env.NEXT_PUBLIC_STAGE;
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function getApiUrl() {
  if (!process.env.NEXT_PUBLIC_API_URL) {
    throw new Error('NEXT_PUBLIC_API_URL is not defined');
  }

  return process.env.NEXT_PUBLIC_API_URL;
}
